import React from "react";
import { Helmet } from "react-helmet";
import SEO from "../components/seo";
import Layout from "../components/layout";
import NewLeadForm from "../components/NewLeadForm";
import VirtualEventFormPlaceholder from "../components/VirtualEventFormPlaceholder";
import "../styles/page/virtual-event-lp.scss";
import { graphql } from "gatsby";
import formIcon from "../assets/images/form-icon.png";
import virtualBgImg from "../assets/virtual-img-bg.svg";
import CustomLink from "../components/CustomLink";

const EventsSection = ({ eventsData }) => {
  return (
    <div className="flex flex-wrap justify-start">
      {eventsData?.items?.map((item, index) => {
        return (
          <div
            key={index}
            className="flex lg:mb-7 md:mb-5 mb-3 lg:px-2 md:px-1 px-[2px] items-start max-w-[50%] min-w-[50%]"
          >
            <img
              src={
                "https://encord.cdn.prismic.io/encord/ced20fb9-72a2-437a-8e35-761d3bce3496_Vector.svg"
              }
              alt="tick icon"
              className="lg:mt-1 md:mt-1 mt-[2px] w-[12px] md:w-[15px] h-auto"
              loading="lazy"
              width={0}
              height={0}
            />
            <div className="text-gray-600 lg:text-base md:text-sm text-xs font-normal   lg:ml-[14px] md:ml-[10px] ml-[5px] lg:max-w-[270px] md:max-w-[250px] max-w-[150px]">
              {item?.list_item?.text}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const AvatarSection = ({
  avatarImg,
  avatarImgAlt,
  avatartTitle,
  avatarDescription,
}) => {
  return (
    <div className="flex flex-col lg:max-w-[300px] md:max-w-[250px] max-w-[200px]">
      <div className="relative lg:max-w-[200px] md:max-w-[200px] max-w-[200px] lg:mb-[14px] md:mb-[14px] mb-[14px]">
        <img
          src={virtualBgImg}
          className="lg:h-[185px] md:h-[185px] h-[185px] lg:max-w-[200px] md:max-w-[200px] max-w-[200px]"
          alt="img"
          loading="lazy"
        />
        <img
          src={avatarImg}
          className="absolute lg:rounded-[70px] md:rounded-[70px] rounded-[70px] lg:top-[23px] md:top-[23px] top-[23px] lg:right-[34px] md:right-[34px] right-[34px] w-[139px] h-[139px]"
          alt={avatarImgAlt || "img"}
          loading="lazy"
          width={0}
          height={0}
        />
      </div>

      <div className="text-purple-1000 lg:text-xl md:text-lg text-base font-semibold  lg:max-w-[200px]">
        {avatartTitle}
      </div>
      <div className="text-purple-1000 lg:text-lg md:text-base text-sm font-normal lg:max-w-[200px]  lg:mb-14 md:mb-8 mb-6">
        {avatarDescription}
      </div>
    </div>
  );
};

const HeroSection = ({ data }) => {
  const [trusted_brands_list] = data?.body?.filter(
    (v) => v.slice_type === "trusted_brands_list"
  ) || [[]];

  const [speaker] = data?.body?.filter((v) => v.slice_type === "speaker") || [
    [],
  ];

  const [event_topics] = data?.body?.filter(
    (v) => v.slice_type === "event_topics"
  ) || [[]];

  return (
    <div className="flex flex-col lg:max-w-[870px] md:max-w-[870px] max-w-[870px] page_background_gradiant">
      <div className="lg:pl-[128px] md:pl-[30px] pl-[20px] lg:pr-[75px] md:pr-[30px] pr-[20px] flex flex-col space-y-3">
        <div className="mb-2 text-xs font-semibold text-purple-500 lg:text-base md:text-sm lg:mb-6 md:mb-3">
          {data?.page_label?.text}
        </div>
        <div className="flex">
          <img
            src="https://encord.cdn.prismic.io/encord/46e41f1c-57a8-4a81-9043-6b46e6a0f350_Vector.svg"
            alt="meeting icon"
            className="w-4 md:w-[24px] md:h-[24px] h-[20px]"
            loading="lazy"
            width={0}
            height={0}
          />
          <span className="ml-1 text-sm font-normal lg:ml-2 md:ml-1 text-purple-1000 lg:text-lg md:text-base ">
            {data?.meeting_date?.text}
          </span>
        </div>
        <div
          className="text-xl font-bold hero_heading_gradiant lg:text-3xl lg:leading-10 md:text-2xl"
          dangerouslySetInnerHTML={{
            __html: data?.page_heading?.html,
          }}
        />
        <div className="text-xl font-bold text-purple-1000 lg:text-3xl md:text-2xl ">
          {data?.page_sub_heading?.text}
        </div>
        <div className="flex flex-col items-center justify-between mt-12 mb-6 border-b border-gray-200 md:flex-row md:items-start lg:mb-14 md:mb-8 lg:mt-12 md:mt-12">
          {speaker?.items &&
            !!speaker?.items.length &&
            speaker?.items.map((speakerData) => (
              <AvatarSection
                avatarImg={speakerData?.speaker_image?.url}
                avatarImgAlt={speakerData?.speaker_image?.alt}
                avatartTitle={speakerData?.speaker_name?.text}
                avatarDescription={speakerData?.speaker_designation?.text}
              />
            ))}
        </div>
        <div className="mb-1 text-base font-semibold text-gray-600 lg:text-xl md:text-lg lg:mb-3 md:mb-2">
          {event_topics?.primary?.section_heading?.text}
        </div>
        <div
          className="mb-2 text-xs font-normal text-gray-600 lg:text-base md:text-sm lg:mb-8 md:mb-4"
          dangerouslySetInnerHTML={{
            __html: event_topics?.primary?.section_description?.html,
          }}
        />

        <EventsSection eventsData={event_topics} />
      </div>

      <section className="mx-auto lg:pt-[70px] md:pt-[40px] pt-[30px] pb-4 lg:space-y-[16px] md:space-y-[8px] space-y-[4px]">
        <section>
          <p className="font-normal text-center uppercase lg:text-sm md:text-xs text-2xs text-gray-1300">
            {trusted_brands_list?.primary?.section_label?.text}
          </p>
        </section>
        <section className="grid grid-cols-3 md:gap-8 gap-4 md:grid-cols-8 lg:pt-[23px] md:pt-[15px] pt-[10px] lg:px-7 md:px-5 px-3">
          {trusted_brands_list?.items?.map((item, index) => {
            return (
              <div
                key={`brand_${index}`}
                className="flex justify-center col-span-1 md:col-span-2 lg:col-span-1"
              >
                <img
                  src={item?.brand_image?.url}
                  alt={item?.brand_image?.alt || "brand logo"}
                  className={`${"object-scale-down h-auto md:w-auto w-[100px]"} img_grayscale`}
                  loading="lazy"
                  width={0}
                  height={0}
                />
              </div>
            );
          })}
        </section>
      </section>
    </div>
  );
};

const PageContent = ({ data }) => {
  const _data =
    data?.prismicVirtualEventLandingPage &&
    data.prismicVirtualEventLandingPage?.data;

  return (
    <div className="lg:max-w-[1507px] md:max-w-[768px] mx-auto lg:pt-40 md:pt-20 pt-20">
      <section className="flex flex-col lg:flex-row">
        <HeroSection data={_data} />

        <div className="lg:max-w-[650px] md:max-w-[650px] max-w-[650px] mx-auto lg:px-4 md:px-3 px-2 lg:my-12 md:my-8 my-4">
          <section className="flex flex-col lg:p-7 md:p-5 p-3 lg:max-w-[456px] md:max-w-[456px] max-w-[456px] w-full lg:rounded-[30px] md:rounded-2.5xl rounded-[10px] form_shadow">
            <div className="mb-2 text-lg font-semibold text-purple-1000 lg:text-2xl md:text-xl lg:mb-5 md:mb-3">
              {_data?.form_heading?.text}
            </div>

            <div className="mb-4 font-normal tracking-tighter text-purple-1000 lg:text-sm md:text-xs text-2xs lg:mb-10 md:mb-6">
              {_data?.form_description?.text}
            </div>
            <span className="hs-event-landing-page">
              <NewLeadForm
                region="eu1"
                portalId="25381551"
                hideCommercialClass
                loader={<VirtualEventFormPlaceholder />}
                formId={
                  _data?.hubspot_form_id?.text ||
                  "dee1c87e-1e61-4fbc-bd60-16c1ac5a12ed"
                }
              />
            </span>
            <div className="lg:text-2xs md:text-[9px] text-[8px] font-normal text-purple-1000 lg:mx-2 md:mx-1 mx-[2px]">
              Encord needs the contact information you provide to us to contact
              you about our products and services. For information on how to
              unsubscribe, as well as our privacy practices and commitment to
              protecting your privacy, please review our{" "}
              <CustomLink
                to={"/privacy/"}
                className="underline transition duration-150 ease-in-out hover:text-indigo-500"
              >
                Privacy Policy
              </CustomLink>
              .
            </div>
            <div className="flex justify-center mt-12">
              <img
                src={formIcon}
                className="lg:max-w-[178px] md:max-w-[150px] max-w-[100px] w-full h-auto"
                alt="img"
                loading="lazy"
                width={0}
                height={0}
              />
            </div>
          </section>
        </div>
      </section>
    </div>
  );
};

const VirtualEventPage = ({ location, data }) => {
  return (
    <Layout location={location} hideFooter>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          hreflang="en"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          hreflang="en"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <PageContent data={data} />
    </Layout>
  );
};

export const query = graphql`
  query PrismicVirtualEventLandingPage($uid: String!) {
    prismicVirtualEventLandingPage(uid: { eq: $uid }) {
      data {
        body {
          ... on PrismicVirtualEventLandingPageDataBodyEventTopics {
            id
            items {
              list_item {
                html
                text
              }
            }
            slice_type
            primary {
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
            }
          }
          ... on PrismicVirtualEventLandingPageDataBodySpeaker {
            id
            items {
              speaker_designation {
                html
                text
              }
              speaker_image {
                alt
                url
              }
              speaker_name {
                html
                text
              }
            }
            slice_type
          }
          ... on PrismicVirtualEventLandingPageDataBodyTrustedBrandsList {
            id
            items {
              brand_image {
                alt
                url
              }
            }
            primary {
              section_label {
                html
                text
              }
            }
            slice_type
          }
        }
        form_description {
          html
          text
        }
        form_heading {
          html
          text
        }
        meeting_date {
          html
          text
        }
        meta_description {
          html
          text
        }
        meta_heading {
          html
          text
        }
        page_heading {
          html
          text
        }
        page_sub_heading {
          html
          text
        }
        hubspot_form_id {
          text
        }
        page_label {
          html
          text
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const _data =
    data?.prismicVirtualEventLandingPage &&
    data.prismicVirtualEventLandingPage?.data;

  return (
    <SEO
      title={_data?.meta_heading?.text}
      description={_data?.meta_description?.text}
    />
  );
};

export default VirtualEventPage;
